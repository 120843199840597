import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ModalService } from "app/componentes/modal/modal.service";
import numberFormatter from "app/util/misc/numberFormatter";
import { ReplaySubject, Subject, Subscription } from "rxjs";
import { RespostaPergunta } from "../resposta-pergunta-listagem-tabela/model/respostaPergunta";
import { TipoPerguntaIconFactoryService } from "../tipoPerguntaIconFactory.service";

@Component({
  selector: "app-resposta-visualizacao-dialog",
  templateUrl: "./resposta-visualizacao-dialog.component.html",
  styleUrls: ["./resposta-visualizacao-dialog.component.scss"],
})
export class RespostaVisualizacaoDialogComponent implements OnInit, OnDestroy {
  respostaPergunta: RespostaPergunta;
  // private respostaPerguntaSubject : Subject<RespostaPergunta> = new ReplaySubject();
  private numPergunta: number;

  // subject que notifica quando o botão "proxima" é apertado dentro de uma pergunta, o payload é
  // o id da pergunta aberta quando o click do botão foi disparado.
  proximaPerguntaSubject: Subject<number> = new ReplaySubject();

  // subject que recebe notificações de quando novas versões de respostaPergunta são emitidas.
  // entenda novas versões como edições de resposta pergunta
  novaRespostaPerguntaSubject: Subject<RespostaPergunta>;

  // subject que notifica quando o botão "cancelar" é disparado
  closeRespostaSubject: Subject<{}> = new ReplaySubject();
  private closeRespostaSubscription: Subscription;

  ehUltimaPergunta: boolean;

  // tslint:disable-next-line: max-line-length
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<RespostaVisualizacaoDialogComponent>,
    private iconFactoryService: TipoPerguntaIconFactoryService,
    private modalService: ModalService
  ) {}

  ngOnInit() {
    this.respostaPergunta = this.data.respostaPergunta;
    this.numPergunta = this.data.numPergunta;
    this.novaRespostaPerguntaSubject = this.data.novaRespostaPerguntaSubject;

    this.ehUltimaPergunta = this.data.ehUltimaPergunta
      ? this.data.ehUltimaPergunta
      : false;

    this.closeRespostaSubscription = this.closeRespostaSubject.subscribe(
      (payload) => {
        this.closeDialog();
      }
    );
  }

  ngOnDestroy() {
    if (this.closeRespostaSubscription) {
      this.closeRespostaSubscription.unsubscribe();
    }
  }

  getFormattedNumPergunta(): string {
    return numberFormatter.addZeroBeforeIfLessThan10(this.numPergunta);
  }

  onSalvar() {
    this.modalService.showModal({
      title: "Salvar alteração",
      messageModal:
        "Ao realizar está ação você atualizará alguns dados da sua avaliação",
      btnTitlePositive: "Salvar",
      btnTitleNegative: "Cancelar",
      icon: "fa-light fa-circle-info",
      positiveCallback: () =>
        this.proximaPerguntaSubject.next(this.respostaPergunta.pergunta.id),
    });
  }

  onProximaPergunta() {
    this.proximaPerguntaSubject.next(this.respostaPergunta.pergunta.id);
  }

  getIcon(tipoPergunta: string) {
    return this.iconFactoryService.getIcon(tipoPergunta);
  }

  /**
   * Callback disparado ao clicar no botão "cancelar" visualização/edição da resposta.
   */
  onCancelar() {
    this.closeDialog();
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
