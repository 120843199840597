import { FilterIcon, FilterTypes } from "app/componentes/filter-select/constants";
import { SurveyListOrderTypes, SurveyListSortingAttributes } from "..";
import { IFilterSelect } from "../../interfaces";

export const SORTING_FILTER: IFilterSelect[] = [
  {
    id: 1,
    label: "Avaliações de A a Z",
    key: `${SurveyListSortingAttributes.TITULO}-${SurveyListOrderTypes.ASC}`,
  },
  {
    id: 2,
    label: "Avaliações de Z a A",
    key: `${SurveyListSortingAttributes.TITULO}-${SurveyListOrderTypes.DESC}`,
  },
  {
    id: 3,
    label: "Avaliações de A a Z",
    key: `${SurveyListSortingAttributes.CLIENTE}-${SurveyListOrderTypes.ASC}`,
  },
  {
    id: 4,
    label: "Avaliações mais recentes",
    key: `${SurveyListSortingAttributes.DATA_CADASTRO}-${SurveyListOrderTypes.DESC}`,
  },
  {
    id: 5,
    label: "Avaliações mais antigas",
    key: `${SurveyListSortingAttributes.DATA_CADASTRO}-${SurveyListOrderTypes.ASC}`,
  },
];

export const SORTING_FILTER_DEFINITIONS = {
  icon: FilterIcon.SORTING,
  type: FilterTypes.RADIO,
};
