<div class="table-container">
  <span class="total-citation">{{citacoes?.length}} respostas</span>
  <div class="table-header">
    <th scope="header-item" class="th-citation">Citação</th>
    <th scope="header-item">Total</th>
    <th scope="header-item">Porcentagem</th>
  </div>
  <div class="table-body">
    <div class="row" *ngFor="let tr of trBodies">
      <div class="cell" title="Citação">
        <input type="checkbox" class="checkbox" (change)="onRespostaPerguntaSelect(tr, $event)" [checked]="tr.selecionado">
        {{ tr.perguntaResposta.citacao }}
      </div>
      <div class="cell" title="Total">
        {{ tr.perguntaResposta.total }}
      </div>
      <div class="cell" title="Porcentagem">
        {{ tr.perguntaResposta.porcentagem }}%
      </div>
    </div>
  </div>
</div>
<app-loading *ngIf="isLoading"></app-loading>
