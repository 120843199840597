<!-- componente modal -->
<div class="co-backdrop">
  <div class="co-container">
    <!-- título do modal -->
    <div class="co-container-header">
      <!-- título -->
      <div>
        <i class="fa-user-plus fa-regular fa-lg"></i>
        <p>Entrevistadores em <span>{{ values.title }}</span></p>
      </div>
      <!-- botao de fechar o modal -->
      <i class="fa-regular fa-circle-xmark fa-lg close-icon"
        (click)="closeModal()"
        (mouseenter)="closeButtonIsHovered = true"
        (mouseleave)="closeButtonIsHovered = false"
      ></i>
    </div>
    <!-- corpo do modal -->
    <div class="co-body">
      <!-- itens -->
      <div *ngFor="let item of data; let i = index">
        <app-select-operator
          label="Entrevistador"
          placeholder="Selecione um entrevistador"
          [data]="operators"
          [operadoresSelecionados]="operadoresSelecionados"
          [selected]="item.item"
          [selectedValue]="item.value"
          [maxRange]="maxRange"
          [howManyLeft]="leftToComplete"
          [changedByInput]="isInputOperatorOverpassedMaxRange(i)"
          [maxValue]="getAmountOfDistribuionOfSamplesByOperator()"
          [totalValue]="values.value"
          (changeSelectedItem)="handleSelectItem(i, $event)"
          (inputValue)="handleChange(i, $event)"
          (onDelete) = "handleDelete(i)"
        ></app-select-operator>
      </div>
      <!-- botões -->
      <div [class]="(getAmountOfDistribuionOfSamplesByOperator() > 0 && editing) ? 'btn-add' : 'btn-add-disabled'" (click)="handleAddOptionClick()">
        <i class="fa-regular fa-circle-plus fa-2xl"></i>
      </div>
      <!-- informativo -->
      <div class="info-collections">
        <p>
          Coletas a serem distribuídas:
          <span>{{ getAmountOfDistribuionOfSamplesByOperator() <= 0 ? '00' : getAmountOfDistribuionOfSamplesByOperator() }}</span>
        </p>
      </div>
      <div class="cabecalho" *ngIf="overpassSample">
        <i class="fas fa-exclamation-circle"></i>
        <p>
          Você excedeu a quantidade máxima de coletas redestribua as amostras
        </p>
      </div>
    </div>
    <!-- rodapé do modal -->
    <div class="footer flex space-between">
      <button class="footer-cancel-button" (click)="closeModal()">
        Cancelar
      </button>
      <button
        class="footer-save-button"
        (click)="handleClick()"
        [disabled]="getAmountOfDistribuionOfSamplesByOperator() < 0 || getAmountOfDistribuionOfSamplesByOperator() > 0"
      >
        Salvar
      </button>
    </div>
  </div>
</div>
