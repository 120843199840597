import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ModalService } from 'app/componentes/modal/modal.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-new-foto-uploader',
  templateUrl: './new-foto-uploader.component.html',
  styleUrls: ['./new-foto-uploader.component.scss'],
})
export class NewFotoUploaderComponent implements OnInit, OnDestroy {
  // marcador para a exibição da animação de carregamento
  showLoading: boolean = false;
  // url da foto que pode tanto ser uma URI ou a codificação base64
  url: string;
  // subscription caso a imagen seja adquirida por uma url
  imageProviderSubscription: Subscription;
  // imagem padrão do componente
  defaultImage = 'https://s3.amazonaws.com/alfa-tensai-teste/default-images/placeholder-upload.png';
  // controla caso a imagem tenha sido alterada
  updatedImage = false
  // referencia para o input DOM
  @ViewChild('fileInput', { static: true }) fileInputElRef: ElementRef;

  @Input() newSize = {};

  /**
   * Mime types aceitos para as imagens
   */
  private validFormats = ['image/jpeg', 'image/jpg'];

  constructor(private modalService: ModalService) {}

  ngOnInit() {
    this.url = this.defaultImage;
  }

  //  Preview do upload de imagem
  onFileSelected(event: any) {
    if (event.target.files && event.target.files[0]) {

      const file: File = event.target.files[0];

      if (this.isValidImage(file)) {
        // dizendo que a imagem foi alterada
        this.updatedImage = true;
        const reader = new FileReader();

        reader.onload = (event: ProgressEvent) => {
          const processedFile = (<FileReader>event.target).result;
          this.url = <string>processedFile;
        };

        reader.readAsDataURL(file);
      }
    }
  }

  loadImageByUrl(url: string) {
    this.url = url;
    // dizendo que a imagem foi alterada
    this.updatedImage = true;
  }

  ngOnDestroy(): void {
    if (this.imageProviderSubscription) {
      this.imageProviderSubscription.unsubscribe();
    }
  }

  isValidImage(file: File): boolean {

    // maximo de 3mb por foto
    const maxImageSize = 3242880;

    if (file.size > maxImageSize) {
      this.modalService.showModal({
        title: 'Seleção de imagem',
        messageModal: 'A imagem deve possuir até 3mb de tamanho',
        btnTitlePositive: "Entendi",
        icon: "fa-light fa-circle-info",
        isOnlyConfirmation: true
      });

      return false;
    } else if (!this.validFormats.includes(file.type)) {
      this.modalService.showModal({
        title: 'Seleção de imagem',
        messageModal: 'A imagem deve estar no formato jpeg',
        btnTitlePositive: "Entendi",
        icon: "fa-light fa-circle-info",
        isOnlyConfirmation: true
      });

      return false;
    }

    return true;
  }

  // resetando os valores de seleção
  clearSelectedImage() {
    this.url = this.defaultImage
    this.updatedImage = false
  }
}
