<div class="info-section-container animate-up delay-2">
  <div class="info-header animate-up delay-3">
    <i class="fa-light fa-clipboard-list fa-xl"></i>
    <h4>{{ pesquisaBody.descricaoPesquisa.titulo }}</h4>
  </div>

  <div
    class="info-descricao animate-up delay-3"
    [ngClass]="!pesquisaBody.vinculos ? 'change-spacing' : 'normal-spacing'"
  >
    <div class="info-item">
      <h5>Cliente:</h5>
      <span> {{ client }} </span>
    </div>
    <div class="info-item">
      <h5>Descrição:</h5>
      <span>
        {{ pesquisaBody.descricaoPesquisa.objetivo }}
      </span>
    </div>
    <div
      class="info-item"
      *ngIf="pesquisaBody.vinculos"
      [ngClass]="!pesquisaBody.vinculos ? 'item-border' : 'normal-border'"
    >
      <h5>Local:</h5>
      <span> {{ pesquisaBody.vinculos[0].localidade.nomeLocalidadePai }} </span>
    </div>
    <div class="info-item">
      <h5>Tipo:</h5>
      <span>
        {{ handleConvertString(pesquisaBody.descricaoPesquisa.tipoPesquisa) }}
      </span>
    </div>
  </div>

  <div class="info-configuracoes animate-up delay-3">
    <div class="configuracoes-item">
      <span>Amostra total</span>
      <h4>
        {{
          handleSumTotal(
            pesquisaBody.configuracaoPesquisa.amostrasPresenciais,
            pesquisaBody.configuracaoPesquisa.amostrasOnline
          )
        }}
      </h4>
    </div>
    <div
      class="configuracoes-item"
      *ngIf="this.isHibrid || this.isOnlyPresencial"
    >
      <span>Amostra presencial</span>
      <h4>
        {{ pesquisaBody.configuracaoPesquisa.amostrasPresenciais }}
      </h4>
    </div>
    <div class="configuracoes-item" *ngIf="this.isHibrid || this.isOnlyOnline">
      <span>Amostra online</span>
      <h4>
        {{ pesquisaBody.configuracaoPesquisa.amostrasOnline }}
      </h4>
    </div>
    <div
      class="configuracoes-item"
      *ngIf="this.isHibrid || this.isOnlyPresencial"
    >
      <span>Tempo entre entrevistas</span>
      <h4>{{ pesquisaBody.configuracaoPesquisa.tempoMinimo }}</h4>
    </div>
    <div
      class="configuracoes-item"
      *ngIf="this.isHibrid || this.isOnlyPresencial"
    >
      <span>Distância entre entrevistas</span>
      <h4>{{ pesquisaBody.configuracaoPesquisa.distancia }}m</h4>
    </div>
  </div>

  <div class="info-periodo-entrevista animate-up delay-3">
    <div class="calendario">
      <div class="calendario-flag">
        <span>Calendário informativo da avaliação</span>
      </div>
      <app-calendar [model]="schedules" [usedBy]="'INFO'"></app-calendar>
    </div>
    <div class="dados-gerais-coleta">
      <div class="dados-gerais-header">
        <i class="fa-light fa-calendar-range" alt="Calendário"></i>
        <h4>Dados gerais sobre o período de coleta</h4>
      </div>
      <span class="divider"></span>

      <div class="datas-resumo-container">
        <div class="data-item" id="inicio">
          <h5>Início</h5>
          <span>{{ startDate }}</span>
        </div>
        <div class="data-item" id="fim">
          <h5>Fim</h5>
          <span>{{ endDate }}</span>
        </div>
        <div
          class="data-item"
          id="intervalos"
          *ngIf="dateIntervalByMonths.length > 0"
        >
          <h5>Intervalos</h5>
          <div class="intervalos-list">
            <span *ngFor="let data of dateIntervalByMonths">
              {{ data.days }} de {{ data.month }} de {{ data.year }}
            </span>
          </div>
        </div>
        <div class="dataitem" id="empty"></div>
        <div class="data-item" id="ciclo">
          <h5>Ciclo</h5>
          <span
            >{{
              pesquisaBody.configuracaoPesquisa.agendamentos.length
            }}
            dias</span
          >
        </div>
      </div>
    </div>
  </div>
</div>
