<div class="card-section-container animate-up delay-2">
  <div class="card-header animate-up delay-3">
    <div class="card-info animate-up delay-3">
      <div class="image">
        <img src="../../../../../assets/images/girl-smilling.png" alt="Teste" />
      </div>
      <div class="label-descricao animate-up delay-3">
        <h4>Questionário</h4>
        <p>Seções e perguntas da sua avaliação</p>
      </div>
    </div>

    <div class="card-perguntas animate-up delay-3">
      <span
        >Total de perguntas: <strong>{{ amountOfQuestions }}</strong></span
      >
    </div>

    <button
      type="button"
      class="card-opcao animate-up delay-3"
      (click)="handleShowBody()"
    >
      <i class="fa fa-chevron-right" aria-hidden="true" *ngIf="!showBody"></i>
      <i class="fa fa-chevron-down" aria-hidden="true" *ngIf="showBody"></i>
    </button>
  </div>
  <div class="card-body animate-up delay-3" *ngIf="showBody">
    <div
      class="secoes-list animate-up delay-3"
      *ngFor="let secao of pesquisaBody.elementosQuestionario"
    >
      <h4>Seção {{ secao.ordem }} - {{ secao.secao.nome }}</h4>
      <div class="secao-item animate-up delay-3">
        <app-question-card
          *ngFor="let question of secao.secao.elementosSecao"
          [pergunta]="question"
        ></app-question-card>
      </div>
    </div>
  </div>
</div>
