import { Component, Input, EventEmitter, Output, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { SelectDataItem, SelectedItemEvent } from '../../componentes/filterable-select/filterable-select-component'
import { NotificatorService } from "app/notificador/notificator.service";

export interface OperatorData {
  label: string;
  value: number;
  item?: SelectedItemEvent;
}

export interface CreateOperatorInput {
  value: number;
  index: number;
  title: string;
  operators: OperatorData[];
}

export interface CreateOperatorOutput {
  id: number;
  label: string;
  value: number;
}

@Component({
  selector: 'app-create-operator',
  templateUrl: './create-operator.component.html',
  styleUrls: ['./create-operator.component.scss']
})
export class CreateOperatorComponent implements OnChanges {
  constructor(private notificatorService: NotificatorService) {}

  @Input()
  values: CreateOperatorInput;
  @Input()
  callbackFunction: () => void;
  @Output()
  selectedItems: EventEmitter<SelectDataItem[]> = new EventEmitter();
  @Input()
  operators: SelectDataItem[]

  data: OperatorData[] = [];
  editing: boolean = true;

  //  Varuiavel para controlar o estado infomrando se o botão
  //  de fechar o modal está com o cursor encima dele ou não,
  //  a fim de ocontrolar, no html, a cor do ícone a ser exibido na visão
  //  de acordo com o padrão de hover de ícones no figma.
  closeButtonIsHovered = false;

  maxRange: number;
  minxRange: number = 0;
  leftToComplete: number;
  lastOperatorChangeByIndex: number;

  overpassSample: boolean = false;

  // timer para debounce do digitar do texto
  timerId = null;
  timerOperatorValue = null;

  operadoresSelecionados: OperatorData[] = [];

  alocateOperatorMessage = {
    title: 'Entrevistadores alocados ',
    description: 'Operação feita com sucesso!'
  };

  // função que verifica se os dados que vem são vazios
  isEmpty(data: OperatorData[]) {
    // Foi alterado o value de 0 para undefined, para que
      // não entre o zero como padrão e o usuario decida que dígito
      // deseja inserir primeiro (que não seja zero).
    if (data.length === 0) {
      this.data = [{
        label: "",
        value: undefined
      }]
    } else {
      this.data = data
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'values': {
            this.isEmpty(this.values.operators);
          }
        }
      }
    }
  }

  // função para deletar operador selecionado
  handleDelete(index: number) {
    const itemToRemoveIndex = this.operadoresSelecionados.findIndex(operadorSelecionado => operadorSelecionado.value === this.data[index].item.item.value)
    if(itemToRemoveIndex > -1) this.operadoresSelecionados.splice(itemToRemoveIndex, 1);
    this.data.splice(index, 1);
    if (this.data.length === 0) {
      this.handleAddOptionClick();
    }
  }

  // adiciona um novo input para operador
  handleAddOptionClick() {
    // verifica se ainda existem amostras a serem distribuidas
    if (this.getAmountOfDistribuionOfSamplesByOperator() > 0 && this.editing) {
      // Foi alterado o value de 0 para undefined, para que
      // não entre o zero como padrão e o usuario decida que dígito
      // deseja inserir primeiro (que não seja zero).
      this.data.push({
        label: "",
        value: undefined
      } as OperatorData);
      this.editing  = false;
    }
  }

  // recupera o valor total dos operadores
  getTotalValue(): number {
    let sum: number = 0;
    for (let i = 0; i < this.data.length; i++) {
      //  Como foi inicializado com undefined, se passar o valor direto na soma
      //  tentaria somar sum + undefined, se o usuario não digitasse nada no input,
      //  resultando em "not a number" (NaN), para evitar que isto acontecesse,
      //  bastou fazer um teste. Se o valor não for undefined, ele retorna o valor, caso
      //  contrário, retorna 0. Aí a soma de sum + 0, resulta em um número válido, uma vez que
      //  sum sempre vai acumular um número válido em todas as iterações do array.
      sum = sum + (!!this.data[i].value ? this.data[i].value : 0);
    }
    return sum
  }

  // recuperar calculo de distribuição das amostras entre os operadores
  getAmountOfDistribuionOfSamplesByOperator(): number {
    return this.values.value - this.getTotalValue();
  }

  /**
   *
   * ENTRADA: index da posição no array de operadores no html
   * REGRA DE NEGÓCIO: Irá validar se o index salvo durante mudança do input e
   * o index enviado via parametro na função são iguais e irá verificar
   * se a flag de overpassSample está ativa
   * SAÍDA: retornará um boolean para ser utilizado como referência e estilizar o input caso ultrapasse o valor de max
   */
  isInputOperatorOverpassedMaxRange(index: number): boolean {
    return index === this.lastOperatorChangeByIndex && this.overpassSample ? true : false;
  }

  // lida com mudanças no valor de um operador e joga o valor para fora do componente
  handleChange(index: number, event: number) {
    this.data[index].value = event;
    this.lastOperatorChangeByIndex = index;
    this.updateLeftAmount(event);
  }

  /**
   * ENTRADA: Valor do último input atualizado
   * REGRA DE NEGÓCIO: irá realizar calculos para determinar o range max do input
   * dependendo das validações ou condições
   * RETORNO: Apenas irá atualizar variáveis de controle
   */
  updateLeftAmount(lastValueInserted: number) {
    this.overpassSample = false;
    clearTimeout(this.timerId);
    this.timerId = setTimeout(() => {
      // percorre o array de operadores e realiza o somatório
      const sum = this.getTotalValue();
      if (sum > this.values.value) {
        // caso o somatório ultrapasse o valor de amostras irá calcular a diferença e atualizar o máximo
        // para que ao pressionar a seta para baixo, o valor atualizado seja dentro do máximo permitido
        this.overpassSample = true;
        const diff = sum - this.values.value;
        this.maxRange = lastValueInserted - diff;
      } else {
        // se for menor, irá atualizar o maxrange e o leftToComplete
        this.maxRange = this.values.value;
      }
      this.leftToComplete = this.values.value - sum;
      if (this.leftToComplete < 0) {
        // se o leftToComplete for menor que zero, a flag overpassSample será atualizada.
        this.overpassSample = true;
        this.leftToComplete = this.values.value - this.leftToComplete;
      }
    }, 250);
  }

  // propaga dados para fora
  handleClick() {
    // verifica se ainda existem amostras a serem distribuidas
    const temp: CreateOperatorOutput[] = [];
    for (let i = 0; i < this.data.length; i++) {
      if (this.data[i].value > 0) {
        // temp.push(this.data[i])
        temp.push({
          id: this.data[i].item.item.value,
          label: this.data[i].item.item.label,
          value: this.data[i].value
        })
      }
    }
    this.selectedItems.emit(temp)
    this.closeModal();

    // Delay de 500ms para mostrar a notificação de distribuição de operadores
    // Como neste passo não é feita nenhuma requisição à api, nós só avisamos
    // ao usuário que ele distribuiu as amostras com sucesso.
    window.setTimeout(() => {
      this.notificatorService.showInfo(this.alocateOperatorMessage.title, this.alocateOperatorMessage.description);
    }, 500);
  }

  handleSelectItem(index: number, event: SelectedItemEvent) {
    const itemToRemoveIndex = this.operadoresSelecionados.findIndex(operadorSelecionado => !!this.data[index].item && !!this.data[index].item.item && operadorSelecionado.value === this.data[index].item.item.value)
    if(itemToRemoveIndex > -1) this.operadoresSelecionados.splice(itemToRemoveIndex, 1);
    this.operadoresSelecionados = [...this.operadoresSelecionados, event.item]
    this.data[index].label = event.item.label
    this.data[index].item = event
    this.editing = true;
  }

  closeModal() {
    this.callbackFunction()
  }
}
